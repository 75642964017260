import CrudView from 'components/Crud/CrudView';
import React from 'react';

export default function Usuarios({...props}){
    return <div>
        <CrudView
            url={`/api/user-geral`} title={'Usuário'}
            columns={[
                {field:'name',title:'Nome'},
                {field:'email',title:'E-mail'},
                {field:'type',title:'Cargo'},
            ]}
            canEdit={false}
            fields={[
                {
                    field:'name',type:"text",grid:{xs:6},label:'Nome *'
                },{
                    field:'email',type:"text",grid:{xs:6},label:'E-mail *'
                },{
                    field:'password',type:"text",grid:{xs:6},label:'Senha *', input: {type: 'password'}
                },{
                    field:'type',type:'select',link:'/api/user-tipos',dataKey:'id', nameKey:'nome',label:'Cargo *',grid:{xs:6}
                },{
                    field:'local_id',type:'select',link:'/api/creas',dataKey:'id', nameKey:'nome',label:'Local *',grid:{xs:12}
                },{
                    field:'cpf',type:"text",grid:{xs:6},label:'CPF'
                },{
                    field:'celular',type:"text",grid:{xs:6},label:'Telefone'
                },{
                    field:'conselho',type:"text",grid:{xs:6},label:'Conselho',
                },{
                    field:'foto',type:'file',grid:{xs:6},label:"Foto", input: {inputProps:{accept: 'image/*'}}
                },{
                    field:'tentativas_acesso',type:"text",grid:{xs:6},label:'Tentativas de conexão',
                },{
                    field:'dias_senha',type:"text",grid:{xs:6},label:'Expiração de senha',
                }
            ]}
        />
    </div>
}
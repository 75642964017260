import React from 'react';
import imgagemEscolhida from '../assets/img/CrasMonteAlegre.jpg'
import {link} from '../links/Links';
import AlmoxarifadoDash from './Dashboard/AlmoxarifadoDash';
import DiretorDash from './Dashboard/DiretorDash';
import TecnicoDash from './Dashboard/TecnicoDash';

export default function Home(){

    const type = localStorage.getItem('type')
    switch(type){
        case'ALM':
        case'ALA':
            return <AlmoxarifadoDash/>
        case'GER':
        case'DIR':
            return <DiretorDash/>
        case'TEC':
        case'REC':
            return <TecnicoDash/>
        case'CON':
        default:
            return (<div>

            </div>);
    }

}
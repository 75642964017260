/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import { style } from "variables/Variables.jsx";

import dashboardRoutes from "routes.js";

import image from "assets/img/sidebar-3.jpg";
import { SelfContext } from "provider/SelfContext";

function Admin({...props}) {
  const [_notificationSystem,set_notificationSystem] = useState(null)
  const [_image,set_image] = useState(image)
  const [color,setColor] = useState('black')
  const [hasImage,setHasImage] = useState(true)
  const [fixedClasses,setFixedClasses] = useState("dropdown show-dropdown open")
  
  const {token} = useContext(SelfContext)
  const {history} = useHistory()

  const getRoutes = () => {
    let type = localStorage.getItem('type');
    const finalRoutes = dashboardRoutes();
    return finalRoutes.map((prop, key) => {
      if (prop.type.indexOf(type) !== -1 && prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = path => {
    const finalRoutes = dashboardRoutes();
    for (let i = 0; i < finalRoutes.length; i++) {
      if (
        props.location.pathname.indexOf(
          finalRoutes[i].layout + finalRoutes[i].path
        ) !== -1
      ) {
        return finalRoutes[i].name;
      }
    }
    return "SADS";
  };
  if(!token){
    return <Redirect
      to={'/'}
    />
  }
    return (
      <div className="wrapper">
        {/*<NotificationSystem ref="notificationSystem" style={style} />*/}
        <Sidebar {...props} 
        // image={this.state.image}
        color={color}
        hasImage={hasImage}/>
        <div id="main-panel" className="main-panel">
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
          <Switch>{getRoutes()}</Switch>
          <Footer />
        </div>
      </div>
    );
}

export default Admin;

import CrudView from 'components/Crud/CrudView'
import React from 'react'

export default function CategoriaAlmoxarifado({...props}){
    return <div>
        <CrudView
            url={'/api/almoxarifado/categoria'}
            columns={[
                {field:'nome',title:'nome'}
            ]}
            title='Categoria'
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:12}
                },{
                    field:'descricao',label:'Descrição',type:'text',input:{rows:4,multiline:true},grid:{xs:12}
                }
            ]}
        />
    </div>
}
import React, { useState, useEffect } from 'react'
import { Backdrop, Button, CircularProgress, Modal } from '@material-ui/core'
import { LaConFetch } from 'links/LaConFetch'
import { _MaterialTableLocalization } from 'links/TableConstant'
import MaterialTable from 'material-table'

import swal from 'sweetalert';
import CrudEdit from './CrudEdit'
import { link } from 'links/Links'

export default function CrudView({url,columns,downloadLink=false,title,fields=[],canEdit=true,canDelete=true,topBar=true,canAdd=true,setOpenSearch=()=>{},extraButton=false,canSee=false,endLink='',extraAction=false,secondExtraAction=false,...props}){
    const [data,setData] = useState([])
    const [sending,setSending] = useState(false)

    const [openModal,setOpenModal] = useState(false)

    const [edit,setEdit] = useState({})
    const [see,setSee] = useState(false)
    const [add,setAdd] = useState(false)

    const loadData=()=>{
        setSending(true)
        LaConFetch(`${url}${endLink}`,(r)=>{
            if(r.success){
                setData(r.items)
            }
            setSending(false)
        },()=>{})
    }

    useEffect(()=>{
        loadData()
    },[url])

    const onClose=(reload=false)=>{
        setOpenModal(false)
        if(reload){
            loadData()
        }
        setEdit({})
        setAdd(false)
    }

    const onDelete=(id)=>{
        setSending(true)
        let form ={}
        LaConFetch(`${url}/delete/${id}`,(r)=>{
            setSending(false)
            if(r.success){
                swal("Removido com sucesso!","", "success");
            }else{
                swal("Oops","Houve um erro ao remover o agendamento", "error");
            }
            loadData()
        },()=>{},form)
    }

    const onAskDelete=(id)=>{
        swal(`Tem certeza que deseja remover o ${title}?`, {
            buttons: {
              remover: "Remover",
              cancel: "Sair"
            },
          })
          .then((value) => {
            switch (value) {
              case "remover":
                onDelete(id)
                break;
            }
          })
    }

    let actions = [];

    if(canEdit){
        actions.push({
            icon:'edit',
            tooltip:'Editar',
            onClick:(e,data)=>{
                setEdit(data)
                setAdd(false)
                setSee(false)
                setOpenModal(true)
            }
        })
    }
    if(canDelete){
        actions.push({
            icon:'delete',
            tooltip:'Remover',
            onClick:(e,data)=>{
                onAskDelete(data.id)
            }
        })
    }
    if(!topBar && canAdd){
        actions.push({
            icon:'add', isFreeAction:true,
            tooltip:'Adicionar',
            onClick:(e,data)=>{
                setEdit({})
                setAdd(true)
                setSee(false)
                setOpenModal(true)
            }
        })
    }
    if(canSee){
        actions.push({
            icon:'visibility',
            tooltip:'Visualizar',
            onClick:(e,data)=>{
                setEdit(data)
                setAdd(false)
                setSee(true)
                setOpenModal(true)
            }
        })
    }
    if(downloadLink){
        actions.push({
            isFreeAction:true,
            icon:'print',
            tooltip:'Baixar',
            onClick:(e,data)=>{
                fetch(`${link}${downloadLink}`,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        'Accept': 'application/json'
                    },
                })
                .then( res => res.blob() )
                .then( blob => {
                    var file = window.URL.createObjectURL(blob);
                    //window.location.assign(file);
                    window.open(file)
                });
            }
        })
    }
    if(extraAction){
        actions.push(extraAction)
    }
    if(secondExtraAction){
        actions.push(secondExtraAction)
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {topBar ? <div className="justify-content-center ml-5">
            <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
                <div className="clearfix">
                    <h4 style={{float:'left',margin:'3px 0'}}>{title}</h4>
                    {extraButton ? <Button onClick={()=>{setOpenSearch(true)}} style={{ float: 'right' }}  variant="contained" color="primary">
                        Pesquisar no SUAS
                    </Button>:null}
                    <Button onClick={()=>{
                        setEdit({})
                        setAdd(true)
                        setOpenModal(true)
                    }} style={{ float: 'right' }}  variant="contained" color="primary">
                        Novo {title}
                    </Button>
                </div>
            </div>
        </div>:null}
        <MaterialTable
            data={data} title={title}
            localization={_MaterialTableLocalization}
            columns={columns}
            options={{
                actionsColumnIndex: -1,
            }}
            actions={actions}
        />
        <Modal open={openModal} onClose={()=>onClose(false)}>
            <div className='container' style={{marginTop:50}}>
                <CrudEdit fields={fields} add={add} oldData={edit} endLink={endLink} onClose={onClose} url={url} title={title} readOnly={see}/>
            </div>
        </Modal>
    </div>
}
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import moment from 'moment'
import { link } from '../../links/Links';
import { LaConFetch } from 'links/LaConFetch';

export default function Relatorio({...props}){
    const [start,setStart] = useState(moment().subtract(1,'month').toDate())
    const [end,setEnd] = useState(moment().toDate())
    const [relatorio,setRelatorio] = useState('')

    const [relatorios,setRelatorios] = useState([
        {id:1,nome: 'Prontuário SUAS'},
        {id:2,nome: 'Atendimento'},
        {id:3,nome: 'Concessão de Benefícios'},
        {id:4,nome: 'Tipos de Atendimento'},
        {id:5,nome: 'Encaminhamentos'},
        {id:6,nome: 'Visitas'},
        {id:7,nome: 'Cidadão - Faixa Etária'},
        {id:8,nome: 'Cidadão - Faixa de Renda'},
    ])

    const [modulos,setModulos] = React.useState([
        {id:'responsavel', name:'Responsável Familiar'},
        {id:'composicao', name:'Composição Familiar'},
        {id:'cond_habitacao', name:'Condições Habitacionais da Família'},
        {id:'cond_educacao', name:'Condições Educacionais da Família'},
        {id:'cond_trabalho', name:'Condições de Trabalho e Rendimentos da Família'},
        {id:'saude', name:'Condições de Saúde da Família'},
        {id:'rendimentos', name:'Rendimentos da Família'},
        {id:'beneficios', name:'Benefícios Eventuais'},
    ]);
    const [modulos2,setModulos2] = React.useState([
        {id:'convivencia', name:'Convivência'},
        {id:'participacao', name:'Participação em Serviços, Programas ou Projetos que contribuam para o desenvolvimento da convivência comunitária e para o fortalecimento de vínculos'},
        {id:'violencia', name:'Violência'},
        {id:'mse', name:'Medida SocioEducativa'},
        {id:'acolhimento', name:'Acolhimento'},
        {id:'estudo', name:'Estudo Social'},
    ]);

    const [paginas, setPaginas] = React.useState({
        responsavel:true,
        composicao:true,
        cond_habitacao:true,
        cond_educacao:true,
        cond_trabalho:true,
        saude:true,
        rendimentos:true,
        beneficios:true,
        convivencia:true,
        participacao:true,
        violencia:true,
        mse:true,
        acolhimento:true,
        estudo:true,
    });

    const handleChange = (key,event) => {
        let hold = {...paginas}
        hold[key] = event.target.checked
        setPaginas(hold);
    };

    const onSubmit=()=>{
        let form = {
            start: moment(start).format('YYYY-MM-DD'),
            end: moment(end).format('YYYY-MM-DD'),
            relatorio,
        }
        if (relatorio==1) {
            form.paginas = paginas
        }
        // LaConFetch(`/api/relatorio`,(r)=>{
            
        // },()=>{},form)

        fetch(`${link}/api/relatorio`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Accept': 'application/json'
            },
            body: JSON.stringify(form),
        })
        .then( res => res.blob() )
        .then( blob => {
            var file = window.URL.createObjectURL(blob);
            //window.location.assign(file);
            window.open(file)
        });
    }

    return <div>
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
          <div className="clearfix">
          <h4 style={{float:'left',margin:'3px 0'}}>Relatórios</h4>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Paper style={{padding:10,marginBottom:10}}>
                <h5>Período do Relatório</h5>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <FormControl
                            fullWidth margin='normal'
                        >
                            <InputLabel>Modelo de relatório</InputLabel>
                            <Select
                                value={relatorio} fullWidth margin='normal'
                                onChange={(e)=>{setRelatorio(e.target.value)}}
                            >
                                <MenuItem value="">
                                    <em>Selecione um modelo de relatório</em>
                                </MenuItem>
                                {relatorios.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <KeyboardDatePicker
                            value={start} onChange={setStart}
                            format="DD/MM/YYYY" label="Começo"
                            fullWidth margin='normal'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <KeyboardDatePicker
                            value={end} onChange={setEnd}
                            format="DD/MM/YYYY" label="Término"
                            fullWidth margin='normal'
                        />
                    </Grid>
                </Grid>
                {(relatorio==1)?(
                    <Grid container spacing={1}>
                        <Grid lg={6} xs={12}>
                            <FormControl component="fieldset">
                                <p>Páginas do Prontuário para Impressão</p>
                                <FormGroup>
                                {modulos.map(
                                    (item,key)=> <FormControlLabel
                                            control={<Checkbox checked={paginas[item.id]} onChange={(e)=>handleChange(item.id,e)} name={item.id} />}
                                            label={item.name}
                                            />
                                )}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid lg={6} xs={12}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                {modulos2.map(
                                    (item,key)=> <FormControlLabel
                                            control={<Checkbox checked={paginas[item.id]} onChange={(e)=>handleChange(item.id,e)} name={item.id} />}
                                            label={item.name}
                                            />
                                )}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                ):null}
                <Button color='primary' variant='contained' onClick={onSubmit}>
                    Gerar Relatório
                </Button>
            </Paper>
        </MuiPickersUtilsProvider>
    </div>
}
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, CircularProgress, Backdrop, Modal, Grid} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import swal from 'sweetalert';
import CPFMask from '../Outros/CPFMask';
import CEPMask from '../Outros/CEPMask';
import {link} from '../../links/Links';
import { LaConFetch } from 'links/LaConFetch';
import cep from 'cep-promise'

function is_cpf(cpf) {	
	cpf = cpf.replace(/[^\d]+/g,'');	
	if(cpf == '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length != 11 || 
		cpf == "00000000000" || 
		cpf == "11111111111" || 
		cpf == "22222222222" || 
		cpf == "33333333333" || 
		cpf == "44444444444" || 
		cpf == "55555555555" || 
		cpf == "66666666666" || 
		cpf == "77777777777" || 
		cpf == "88888888888" || 
		cpf == "99999999999")
			return false;		
    // Valida 1o digito	
    var add = 0;	
    var i;
    var rev;
    for (i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      rev = 11 - (add % 11);	
      if (rev == 10 || rev == 11)		
        rev = 0;	
      if (rev != parseInt(cpf.charAt(9)))		
        return false;		
    // Valida 2o digito	
    add = 0;	
    for (i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11)	
      rev = 0;	
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    return true;   
}

function is_cep(cep) {	
  if(cep === "" || cep === null)
    return true;
	cep = cep.replace(/[^\d]+/g,'');	
  if (cep.length > 0 && cep.length < 8)
			return false;		
  return true;   
}

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

}));
   
export default function Responsavel({familia_id}) {

  const classes = useStyles();

  const [endereco_id, setEndereco_id] = useState("");

  // Dados Pessoais
  const [nm_pessoa, setNm_pessoa] = useState("");
  const [nm_apelido, setNm_apelido] = useState("");
  const [nm_mae, setNm_mae] = useState("");
  const [nm_pai, setNm_pai] = useState("");
  const [nm_naturalidade, setNm_naturalidade] = useState("");
  const [ds_sexo, setDs_sexo] = useState("");
  const [dt_nascimento, setDt_nascimento] = useState("");
  const [nr_nis, setNr_nis] = useState("");
  const [nr_ctps, setNr_ctps] = useState("");
  const [nr_serie_ctps, setNr_serie_ctps] = useState("");
  const [nr_cpf, setNr_cpf] = useState("");
  const [nr_rg, setNr_rg] = useState("");
  const [dt_emissao_rg, setDt_emissao_rg] = useState("");
  const [nm_orgao_emissor, setNm_orgao_emissor] = useState("");
  const [uf_rg, setUf_rg] = useState("");
  const [st_deficiente, setSt_deficiente] = useState("");
  const [st_documento, setSt_documento] = useState("");
  const [ds_documentacao_civil, setDs_documentacao_civil] = useState("");
  const [documentacaoOff, setDocumentacaoOff] = useState(true);

  // Endereço
  const [nm_bairro, setNm_bairro] = useState("");
  const [nm_logradouro, setNm_logradouro] = useState("");
  const [nr_residencia, setNr_residencia] = useState("");
  const [ds_referencia, setDs_referencia] = useState("");
  const [nr_cep, setNr_cep] = useState("");
  const [ds_localizacao, setDs_localizacao] = useState("");
  const [st_abrigo, setSt_abrigo] = useState("");

  const setEndereco = (nr_cep) =>{
    setNr_cep(nr_cep);
    nr_cep = nr_cep.replace(/[^\d]+/g,'');	
    cep(nr_cep)
    .then(result => {
      setNm_bairro(result.neighborhood);
      setNm_logradouro(result.street);
    });
  }

  /* Telefone */
  const [contato, setContato] = useState([{tp_telefone:"", nr_ddd:"", nr_telefone:"", botao:true}]);

  /* Add Telefone */
  const addTel = (pos) =>{
    let add = [...contato];
    add.push({tp_telefone:"", nr_ddd:"", nr_telefone:"", botao:true});
    add[pos].botao = false;
    setContato(add);
  }

  /* Remove Telefone */
  const closeTel = (pos) =>{
    let tel = [...contato];
    tel.splice(pos,1);
    setContato(tel);
  }

  /* Set Tipo Telefone */
  const setTp_telefone = (valor,pos) =>{
    let add = [...contato];
    add[pos].tp_telefone = valor;
    setContato(add);
  }

  /* Set DDD Telefone */
  const setNr_ddd = (valor,pos) =>{
    let add = [...contato];
    add[pos].nr_ddd = valor;
    setContato(add);
  }

  /* Set Numero Telefone */
  const setNr_telefone = (valor,pos) =>{
    let add = [...contato];
    add[pos].nr_telefone = valor;
    setContato(add);
  }

  const [msms,setMSms] = useState(false)
  const [msgsms,setMsgSms] = useState('')
  const [smsnumber,setSmsNumber] = useState('')

  const modalSms = (ac,number) => {
    setSmsNumber(number)
    setMSms(true)
  }

  const sendSms = () => {
    let dados = {
      telefone: smsnumber,
      mensagem: msgsms
    }
    setSending(true);
    LaConFetch(`/api/enviasms`,
    result => {
      setSending(false);
      swal("Mensagem enviada com sucesso!","", "success")
      setMSms(false)
    },()=>{},dados)
  }

  /* Carregar Responsave, Endereco e Telefone */
  const loadResponsavel = () => {

    // setSending(true);

    // fetch(`${link}/api/resultResponsavel/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDados(result.dados[0]))})
    //   .then(result => setSending(false))

    setSending(true);
    LaConFetch(`/api/resultResponsavel/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDados(result.dados[0])
        }
        setSending(false)
    },result => setSending(false))
      
  };

  useEffect(() => {
    
    loadResponsavel();
    
  }, [])

  useEffect(() => {
    
    if(st_documento === 'Sim'){
      setDocumentacaoOff(false);
    } else{
      setDocumentacaoOff(true);
    }
    
  }, [st_documento])

  /* SetDados */
  const setDados = (dados) => {

    /* Responsavel */
    setNm_pessoa(dados.pessoa.nm_pessoa);
    setNm_apelido(dados.responsavel.nm_apelido);
    setNm_mae(dados.responsavel.nm_mae);
    setNm_pai(dados.responsavel.nm_pai);
    setNm_naturalidade(dados.responsavel.nm_naturalidade);
    setDs_sexo(dados.pessoa.ds_sexo);
    setDt_nascimento(dados.pessoa.dt_nascimento);
    setNr_nis(dados.responsavel.nr_nis);
    setNr_ctps(dados.responsavel.nr_ctps);
    setNr_serie_ctps(dados.responsavel.nr_serie_ctps);
    setNr_cpf(dados.responsavel.nr_cpf);
    setNr_rg(dados.responsavel.nr_rg);
    setDt_emissao_rg(dados.responsavel.dt_emissao_rg);
    setNm_orgao_emissor(dados.responsavel.nm_orgao_emissor);
    setUf_rg(dados.responsavel.uf_rg);
    setSt_deficiente(dados.pessoa.st_deficiente);
    setSt_documento(dados.pessoa.setSt_documento);
    if (st_documento === 'Sim'){
      setDocumentacaoOff(false);
    }
    setDs_documentacao_civil(dados.pessoa.ds_documentacao_civil);

    /* Endereço */
    if (dados.endereco && dados.endereco.id) {
      setEndereco_id(dados.endereco.id);
      setNm_bairro(dados.endereco.nm_bairro);
      setNm_logradouro(dados.endereco.nm_logradouro);
      setNr_residencia(dados.endereco.nr_residencia);
      setDs_referencia(dados.endereco.ds_referencia);
      setNr_cep(dados.endereco.nr_cep);
      setDs_localizacao(dados.endereco.ds_localizacao);
      setSt_abrigo(dados.endereco.st_abrigo);
    }

    /* Telefone */
    let holding = dados.telefone.map( (item,pos) => {

      return {

        tp_telefone: item.tp_telefone,
        nr_ddd: item.nr_ddd,
        nr_telefone: item.nr_telefone,
        botao: pos === dados.telefone.length-1
     
      }
    })
    
    setContato(holding)

  };

  const [sending, setSending] = useState(false);

  /* Editar Responsavel*/
  const editar = () => {

    if(nm_pessoa === "")
      swal("Nome é obrigatório!","", "error")

    else if(ds_sexo === "")
      swal("Sexo é obrigatório!","", "error")

    else if(dt_nascimento === "")
      swal("Data de nascimento é obrigatório!","", "error")

    else if(nr_cpf === "")
      swal("CPF é obrigatório!","", "error")

    else if(!is_cpf(nr_cpf))
      swal("CPF inválido!","", "error")

    else if(nr_rg === "")
      swal("RG é obrigatório!","", "error")

    else if(!is_cep(nr_cep))
      swal("CEP tem 8 dígitos!","", "error")
    
    else if(nm_logradouro === "")
      swal("Logradouro é obrigatório!","", "error")

    else if(nr_residencia === "")
      swal("Número da residência é obrigatório!","", "error")

    else{

      let continua = false;

      for(let item of contato){

        if(item.tp_telefone === "" && (item.nr_ddd !== "" || item.nr_telefone !== "")){
          continua = false;
          swal("Preencha todos os campos do contato para enviar!","", "error");
          break;
        }

        if(item.nr_ddd === "" && (item.tp_telefone !== "" || item.nr_telefone !== "")){
          continua = false;
          swal("Preencha todos os campos do contato para enviar!","", "error");
          break;
        }

        else if(item.nr_telefone === "" && (item.nr_ddd !== "" || item.tp_telefone !== "")){
          continua = false;
          swal("Preencha todos os campos do contato para enviar!","", "error");
          break;
        }

        else{
          continua = true;
        }
      };

      if (continua === true) {

        const formDadosResp = {

          // Responsável
          nm_pessoa,
          nm_apelido,
          nm_mae,
          nm_pai,
          nm_naturalidade,
          ds_sexo,
          dt_nascimento,
          nr_nis,
          nr_ctps,
          nr_serie_ctps,
          nr_cpf,
          nr_rg,
          dt_emissao_rg,
          nm_orgao_emissor,
          uf_rg,
          st_deficiente,
          st_documento,
          ds_documentacao_civil,

          // Endereco
          endereco_id,
          nm_bairro,
          nm_logradouro,
          nr_residencia,
          ds_referencia,
          nr_cep,
          ds_localizacao,
          st_abrigo,

          // Telefone
          contato   
        };

        setSending(true);

        // fetch(`${link}/api/editarResponsavel/${familia_id}`,{
        //   method:'POST',
        //   body:JSON.stringify(formDadosResp),
        //   headers:{
        //       "Content-Type":'application/json',
        //       Accept:'application/json'
        //   }
        // })
        // .then(res => res.json())
        // .then(result => {
        //   setSending(false)
        // })
        // .then(swal("Atualizado com sucesso!","", "success"));

        setSending(true);
        LaConFetch(`/api/editarResponsavel/${familia_id}`,
        result => {
          loadResponsavel();
          setSending(false);
          swal("Atualizado com sucesso!","", "success")
        },()=>{},formDadosResp)

      }

    }
  }

  return (

    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

       {/* --------------------------- Responsavel --------------------------- */}

      <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Dados Pessoais</InputLabel>

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '49%' }}
        id="nm_pessoa"
        label="Nome*"
        variant="outlined"
        value={nm_pessoa}
        onChange={(e) => setNm_pessoa(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ width: '49%' }}
        id="nm_apelido"
        label="Apelido"
        variant="outlined"
        value={nm_apelido}
        onChange={(e) => setNm_apelido(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '49%' }}
        id="nm_mae"
        label="Nome da Mãe"
        variant="outlined"
        value={nm_mae}
        onChange={(e) => setNm_mae(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ width: '49%' }}
        id="nm_pai"
        label="Nome do Pai"
        variant="outlined"
        value={nm_pai}
        onChange={(e) => setNm_pai(e.target.value)}
      />

      <FormControl style={{ marginTop: 15, marginRight: '2%', width: '20%' }} component="fieldset">
        <FormLabel component="legend">Sexo*</FormLabel>
        <RadioGroup row aria-label="ds_sexo" name="ds_sexo" value={ds_sexo} onChange={(e) => setDs_sexo(e.target.value)}>
          <FormControlLabel value="Feminino" control={<Radio color="primary"/>} label="Feminino" />
          <FormControlLabel value="Masculino" control={<Radio color="primary"/>} label="Masculino" />
        </RadioGroup>
      </FormControl>

      <TextField
        variant="outlined"
        margin="normal"
        style={{ marginRight: '2%', width: '16%' }}
        id="dt_nascimento"
        label="Data de Nascimento*"
        type="date"
        value={dt_nascimento}
        onChange={(e) => setDt_nascimento(e.target.value)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '26%' }}
        id="nm_naturalidade"
        label="Naturalidade"
        variant="outlined"
        value={nm_naturalidade}
        onChange={(e) => setNm_naturalidade(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ width: '32%' }}
        id="nr_nis"
        label="Nº NIS"
        variant="outlined"
        value={nr_nis}
        onChange={(e) => setNr_nis(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '32%' }}
        id="nr_ctps"
        label="CTPS"
        variant="outlined"
        value={nr_ctps}
        onChange={(e) => setNr_ctps(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '32%' }}
        id="nr_serie_ctps"
        label="Série"
        variant="outlined"
        value={nr_serie_ctps}
        onChange={(e) => setNr_serie_ctps(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ width: '32%' }}
        id="nr_cpf"
        label="CPF*"
        variant="outlined"
        InputProps={{ inputComponent: CPFMask }}
        value={nr_cpf}
        onChange={(e) => setNr_cpf(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '32%' }}
        id="nr_rg"
        label="RG*"
        variant="outlined"
        value={nr_rg}
        onChange={(e) => setNr_rg(e.target.value)}
      />

      <TextField
        variant="outlined"
        style={{ marginTop: 15, marginRight: '2%', width: '8%' }}
        id="uf_rg"
        value={uf_rg}
        label="RG UF"
        onChange={(e) => setUf_rg(e.target.value)}
        select>
        <MenuItem value="1">AC</MenuItem>
        <MenuItem value="2">AL</MenuItem>
        <MenuItem value="3">AP</MenuItem>
        <MenuItem value="4">AM</MenuItem>
        <MenuItem value="5">BA</MenuItem>
        <MenuItem value="6">CE</MenuItem>
        <MenuItem value="7">DF</MenuItem>
        <MenuItem value="8">ES</MenuItem>
        <MenuItem value="9">GO</MenuItem>
        <MenuItem value="10">MA</MenuItem>
        <MenuItem value="11">MT</MenuItem>
        <MenuItem value="12">MS</MenuItem>
        <MenuItem value="13">MG</MenuItem>
        <MenuItem value="14">PA</MenuItem>
        <MenuItem value="15">PB</MenuItem>
        <MenuItem value="16">PR</MenuItem>
        <MenuItem value="17">PE</MenuItem>
        <MenuItem value="18">PI</MenuItem>
        <MenuItem value="19">RJ</MenuItem>
        <MenuItem value="20">RN</MenuItem>
        <MenuItem value="21">RS</MenuItem>
        <MenuItem value="22">RO</MenuItem>
        <MenuItem value="23">RR</MenuItem>
        <MenuItem value="24">SC</MenuItem>
        <MenuItem value="25">SP</MenuItem>
        <MenuItem value="26">SE</MenuItem>
        <MenuItem value="27">TO</MenuItem>
      </TextField>

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '16%' }}
        id="nm_orgao_emissor"
        label="Órgao de Emissão"
        variant="outlined"
        value={nm_orgao_emissor}
        onChange={(e) => setNm_orgao_emissor(e.target.value)}
      />

      <TextField
        variant="outlined"
        margin="normal"
        style={{ width: '16%' }}
        id="dt_emissao_rg"
        label="Data de Emissão"
        type="date"
        value={dt_emissao_rg}
        onChange={(e) => setDt_emissao_rg(e.target.value)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Dados Gerais</InputLabel>

      <FormControl style={{ marginTop: 15, marginRight: '2%', width: '16%' }} component="fieldset">
        <FormLabel component="legend">Possui alguma Deficiência?</FormLabel>
        <RadioGroup row value={st_deficiente} aria-label="st_deficiente" name="st_deficiente" onChange={(e) => setSt_deficiente(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, marginRight: '2%', width: '24%' }} component="fieldset">
        <FormLabel component="legend">Precisa providenciar algum documento?</FormLabel>
        <RadioGroup row value={st_documento} aria-label="st_documento" name="st_documento" onChange={(e) => setSt_documento(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />
        </RadioGroup>
      </FormControl>

      <TextField 
        disabled={documentacaoOff}
        margin="normal"
        style={{ width: '56%' }}
        id="ds_documentacao_civil"
        label="Caso Sim, quais?"
        variant="outlined"
        value={ds_documentacao_civil}
        onChange={(e) => setDs_documentacao_civil(e.target.value)}
      />


      {/* --------------------------- Endereço --------------------------- */}

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '16%' }}
        id="nr_cep"
        label="CEP"
        InputProps={{ inputComponent: CEPMask}}
        variant="outlined"
        value={nr_cep}
        onChange={(e) => setEndereco(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '72%' }}
        id="nm_logradouro"
        label="Logradouro*"
        variant="outlined"
        value={nm_logradouro}
        onChange={(e) => setNm_logradouro(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ width: '8%' }}
        id="nr_residencia"
        label="Número*"
        variant="outlined"
        value={nr_residencia}
        onChange={(e) => setNr_residencia(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ marginRight: '2%', width: '32%' }}
        id="nm_bairro"
        label="Bairro"
        variant="outlined"
        value={nm_bairro}
        onChange={(e) => setNm_bairro(e.target.value)}
      />

      <TextField
        margin="normal"
        style={{ width: '66%' }}
        id="ds_referencia"
        label="Ponto de Referência"
        variant="outlined"
        value={ds_referencia}
        onChange={(e) => setDs_referencia(e.target.value)}
      />

      <FormControl style={{ marginRight: '2%', marginTop: 15, width: '18%' }} component="fieldset">
        <FormLabel component="legend">Vive em situação de abrigo?</FormLabel>
        <RadioGroup row aria-label="st_abrigo" name="st_abrigo" value={st_abrigo} onChange={(e) => setSt_abrigo(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />
        </RadioGroup>
      </FormControl>

      <TextField
        margin="normal"
        style={{ width: '80%' }}
        id="ds_localizacao"
        label="Descrição do local"
        variant="outlined"
        value={ds_localizacao}
        onChange={(e) => setDs_localizacao(e.target.value)}
      />

      {/* --------------------------- Telefone --------------------------- */}

      <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Contato</InputLabel>

      {contato.map((item, pos)=>
        <div>
          <TextField
            variant="outlined"
            style={{ marginTop: 15, marginRight: '2%', width: '14%' }}
            id="tp_telefone"
            value={item.tp_telefone}
            label="Tipo de Telefone"
            onChange={(e) => setTp_telefone(e.target.value, pos)}
            select>
            <MenuItem value="CELULAR">CELULAR</MenuItem>
            <MenuItem value="RESIDENCIAL">RESIDENCIAL</MenuItem>
            <MenuItem value="COMERCIAL">COMERCIAL</MenuItem>
            <MenuItem value="PROFISSIONAL">PROFISSIONAL</MenuItem>
          </TextField>

          <TextField
            margin="normal"
            style={{ marginRight: '2%', width: '8%' }}
            id="nr_ddd"
            label="DDD"
            inputProps={{
              maxLength: 2
            }}
            variant="outlined"
            value={item.nr_ddd}
            onChange={(e) => setNr_ddd(e.target.value, pos)}
          />

          <TextField
            margin="normal"
            style={{ width: '18%', marginRight: '2%' }}
            id="nr_telefone"
            label="Número"
            inputProps={{
              maxLength: 9
            }}
            variant="outlined"
            value={item.nr_telefone}
            onChange={(e) => setNr_telefone(e.target.value, pos)}
          />

          {(item.tp_telefone=='CELULAR') ? (
          <Button onClick={()=>modalSms(true,item.nr_ddd+item.nr_telefone)} variant="contained">Enviar SMS</Button>
          ) : null}

          {item.botao === true?
            <Fab style={{ marginTop:21, marginRight: '40%' }} onClick={(e) => addTel(pos)} size="small" color="primary" aria-label="addFaixa">
              <AddIcon />
            </Fab>
          :null}

          {item.botao === false?
            <Fab style={{ marginTop:21, marginRight: '40%' }} onClick={(e) => closeTel(pos)} size="small" color="secundary" aria-label="closeFaixa">
              <CloseIcon />
            </Fab>
          :null}

        </div>)
      }

      <br></br><br></br>

      <Button onClick={editar} style={{ float: 'right' }} variant="contained" color="primary">
        Editar
      </Button>

      <br></br><br></br>

      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={msms}
          onClose={()=>setMSms(false)}
          closeAfterTransition
          >
            <div className={classes.paper} style={{backgroundColor:'#fff'}}>
          <Grid container>
            <Grid xs={12}>
              <p>Enviar SMS para Beneficiado</p>
              <TextField   
                margin="normal"
                style={{ width: '100%' }}     
                id="msgsms"
                label="Mensagem SMS"
                variant="outlined"
                value={msgsms} 
                onChange={(e) => setMsgSms(e.target.value)}
                multiline='true'
                rows='4'
              />
            </Grid>
            <Grid xs={12}>
              <Button onClick={()=>sendSms()} variant="contained">Enviar SMS</Button>
            </Grid>
          </Grid>
          </div>
      </Modal>

    </div>
)}
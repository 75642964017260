import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {Button, CircularProgress, Backdrop} from '@material-ui/core';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';
import CrudView from 'components/Crud/CrudView';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  
}));
   
export default function Atendimento({familia_id}) {

  const classes = useStyles();

  /* Detalhes do Atendimento */
  const [cd_acesso_atendimento, setCd_acesso_atendimento] = useState("");
  const [ds_acesso_atendimento, setDs_acesso_atendimento] = useState("");
  const [ds_motivacao_atendimento, setDs_motivacao_atendimento] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  /* Registro do Atendimento */
  const [dt_atendimento, setDt_atendimento] = useState("");
  const [ds_atendimento, setDs_atendimento] = useState(""); 
  const [cd_atendimento, setCd_atendimento] = useState("");
  const [nm_tecnico, setNm_tecnico] = useState("");

  /* Valor mostrado em outro */
  const [ds_acesso_atendimento_outro, setDs_acesso_atendimento_outro] = useState("");
  const [ds_atendimento_outro, setDs_atendimento_outro] = useState("");

  /* Desabilitar Outro */
  const [outroAcessoAtendimentoOff, setOutroAcessoAtendimentoOff] = useState(true);
  const [outroAtendimentoOff, setOutroAtendimentoOff] = useState(true);

  /* Carregar Atendimento */
  const load = () => {

    // setSending(true);

    // fetch(`${link}/api/resultAtendimento/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDados(result.dados))})
    //   .then(result => setSending(false))

      setSending(true);
      LaConFetch(`/api/resultAtendimento/${familia_id}`,
        result => {
          if(result.dados !== null){
            setDados(result.dados)
          }
          setSending(false)
      },result => setSending(false))
  };

  useEffect(() => {
      load()
  }, [])

  const setDados = (dados) =>{
    setCd_acesso_atendimento(dados.cd_acesso_atendimento);
    setDs_acesso_atendimento(dados.ds_acesso_atendimento);
    setDs_motivacao_atendimento(dados.ds_motivacao_atendimento);
    setDs_observacao(dados.ds_observacao);
    setDt_atendimento(dados.dt_atendimento)
    setCd_atendimento(dados.cd_atendimento);
    setNm_tecnico(dados.nm_tecnico);
    setAtendimento(dados.cd_atendimento);
    if(dados.cd_acesso_atendimento === '11'){
      setOutroAcessoAtendimento(dados.ds_acesso_atendimento);
      }
    if(dados.cd_atendimento === '8'){
    setOutroAtendimento(dados.ds_atendimento);
    }
  }

   /* Set CD_ACESSO_ATENDIMENTO e DS_ACESSO_ATENDIMENTO */
   const setAcessoAtendimento = (codigo) =>{

    setCd_acesso_atendimento(codigo);

    if (codigo === '1'){  
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Por demanda espontânea');
    }
    else if (codigo === '2'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de Busca Ativa realizada pela equipe da unidade');
    }
    else if (codigo === '3'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento realizado por outros serviços/unidades da Proteção Social Básica');
    }
    else if (codigo === '4'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento realizado por outros serviços/unidades da Proteção Social Especial');
    }
    else if (codigo === '5'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento realizado pela área de Saúde');
    }
    else if (codigo === '6'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento realizado pela área de Educação');
    }
    else if (codigo === '7'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento realizado por outras políticas setoriais');
    }
    else if (codigo === '8'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento pelo Conselho Tutelar');
    }
    else if (codigo === '9'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento pelo Poder Judiciário');
    }
    else if (codigo === '10'){
      setDs_acesso_atendimento_outro(null);
      setOutroAcessoAtendimentoOff(true);
      setDs_acesso_atendimento('Em decorrência de encaminhamento pelo Sistema de Garantia de Direitos (Defensoria Pública, Ministério Público, Delegacias');
    }

    else if(codigo === '11'){
      setOutroAcessoAtendimentoOff(false);
    }

  }

  const setOutroAcessoAtendimento = (texto) =>{

    setDs_acesso_atendimento_outro(texto);
    setDs_acesso_atendimento(texto);
  }
 
  /* Set CD_ATENDIMENTO e DS_ATENDIMENTO */
  const setAtendimento = (codigo) =>{

    setCd_atendimento(codigo);

    if (codigo === '1'){  
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Atendimento socioassistencial individualizado');
    }
    else if (codigo === '2'){
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Atendimento em atividade coletiva de caráter continuado');
    }
    else if (codigo === '3'){
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Participação em atividade coletiva de caráter não continuado');
    }
    else if (codigo === '4'){
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Cadastramento / Atualização Cadastral');
    }
    else if (codigo === '5'){
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Acompanhamento de MSE');
    }
    else if (codigo === '6'){
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Solicitação / Concessão de Benefício Eventual');
    }
    else if (codigo === '7'){
      setDs_atendimento_outro(null);
      setOutroAtendimentoOff(true);
      setDs_atendimento('Visita Domiciliar');
    }

    else if(codigo === '8'){
      setOutroAtendimentoOff(false);
    }
 
  }
  
  const setOutroAtendimento = (texto) =>{
  
    setDs_atendimento_outro(texto);
    setDs_atendimento(texto);
  }
  
  const [sending, setSending] = useState(false);

  /* Editar Atendimento */
  const editar = () => {

    if(dt_atendimento === "")
      swal("Data de atendimento é obrigatório!","", "error")

    else if(nm_tecnico === "")
      swal("Nome do técnico é obrigatório!","", "error")

    else{

      const formDados = {
        cd_acesso_atendimento,
        ds_acesso_atendimento,
        ds_motivacao_atendimento,
        ds_observacao,
        dt_atendimento,
        ds_atendimento,
        cd_atendimento,
        nm_tecnico,
      };

      setSending(true); 

      // fetch(`${link}/api/editarAtendimento/${familia_id}`,{
      //   method:'POST',
      //   body:JSON.stringify(formDados),
      //   headers:{
      //       "Content-Type":'application/json',
      //       Accept:'application/json'
      //   }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   setSending(false)
      // })
      // .then(swal("Editado com sucesso!","", "success"));

      LaConFetch(`/api/editarAtendimento/${familia_id}`,
        result => {
          load();
          setSending(false);
          swal("Enviado com sucesso!","", "success")
        },()=>{},formDados)

    }
  };

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Detalhes do Atendimento</InputLabel>
      
      <TextField
          variant="outlined"
          style={{ marginTop: 15, width: '100%' }} 
          id="cd_acesso_atendimento" 
          value={cd_acesso_atendimento}
          label="De que forma a família (ou membro da família) acessou a Unidade para o primeiro atendimento?"  
          onChange={(e) => setAcessoAtendimento(e.target.value)}
          select
        >
          <MenuItem value="1">Por demanda espontânea</MenuItem>
          <MenuItem value="2">Em decorrência de Busca Ativa realizada pela equipe da unidade</MenuItem>
          <MenuItem value="3">Em decorrência de encaminhamento realizado por outros serviços/unidades da Proteção Social Básica</MenuItem>
          <MenuItem value="4">Em decorrência de encaminhamento realizado por outros serviços/unidades da Proteção Social Especial</MenuItem> 
          <MenuItem value="5">Em decorrência de encaminhamento realizado pela área de Saúde</MenuItem> 
          <MenuItem value="6">Em decorrência de encaminhamento realizado pela área de Educação</MenuItem> 
          <MenuItem value="7">Em decorrência de encaminhamento realizado por outras políticas setoriais</MenuItem> 
          <MenuItem value="8">Em decorrência de encaminhamento pelo Conselho Tutelar</MenuItem> 
          <MenuItem value="9">Em decorrência de encaminhamento pelo Poder Judiciário</MenuItem> 
          <MenuItem value="10">Em decorrência de encaminhamento pelo Sistema de Garantia de Direitos (Defensoria Pública, Ministério Público, Delegacias)</MenuItem> 
          <MenuItem value="11">Outros encaminhamentos</MenuItem>        
        </TextField>

        <TextField
          disabled={outroAcessoAtendimentoOff}
          margin="normal"
          style={{ width: '100%' }}  
          value={ds_acesso_atendimento_outro}
          id="ds_acesso_atendimento_outro"
          label="Caso outro, descreva a situação"
          variant="outlined"
          onChange={(e) => setOutroAcessoAtendimento(e.target.value)}
        />

        <TextField
          margin="normal"
          style={{ width: '100%' }}
          id="ds_motivacao_atendimento"
          label="Quais as razões, demandas ou necessidades que motivaram este primeiro atendimento?"
          variant="outlined"
          value={ds_motivacao_atendimento}
          onChange={(e) => setDs_motivacao_atendimento(e.target.value)}
          multiline='true'
          rows='4'
        />

        <TextField   
          margin="normal"
          style={{ width: '100%' }}     
          id="ds_observacao"
          label="Observação"
          variant="outlined"
          value={ds_observacao} 
          onChange={(e) => setDs_observacao(e.target.value)}
          multiline='true'
          rows='4'
        />

      <Button onClick={editar} style={{ float: 'right' }} variant="contained" color="primary">
        Editar
      </Button>
      <br></br><br></br>
      <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Registro do Atendimento</InputLabel>

      <CrudView
        topBar={false} url={`/api/registro-atendimento`} title='Atendimento'
        canEdit={false} canDelete={false} canSee={true} endLink={`?familia_id=${familia_id}`}
        columns={[
          {field:'id',title:'#'},
          {field:'dt_atendimento',title:'Data do Atendimento',render:(data)=>moment(data.dt_atendimento,'YYYY-MM-DD').format('DD/MM/YYYY')},
          {field:'atendimento.nome',title:'Atendimento',render:(data)=>{
            if(data.atendimento.outro){
              return data.ds_atendimento
            }else{
              return data.atendimento.nome
            }
          }},
          {field:'nm_tecnico',title:'Técnico'},
        ]}
        fields={[
          {
            field:'dt_atendimento',label:'Data do Atendimento',type:'date',grid:{xs:6}
          },{
            field:'nm_tecnico',label:'Técnico',type:'text',grid:{xs:6}
          },{
            field:'atendimento_id',label:'Atendimento',type:'select',grid:{xs:6},link:'/api/code/atendimento',nameKey:'nome',dataKey:'id'
          },{
            field:'ds_atendimento',label:'Caso outro qual?',type:'text',grid:{xs:6}
          },{
            field:'familia_id',value:familia_id,type:'default'
          }
        ]}
      />
      <br></br><br></br>

    </div>
)}
import { _MaterialTableLocalization } from 'links/TableConstant';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import {Button, Card, CardHeader, CardContent, Grid, TextField, MenuItem, Backdrop, CircularProgress, Modal} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {link} from '../links/Links';
import { LaConFetch } from 'links/LaConFetch';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import 'moment/locale/pt-br';
import swal from 'sweetalert';
import ModalMovimento from './Almoxarifado/ModalMovimento';

const useStyles = makeStyles((theme) => ({
  
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function AlmoxMovimentos(){
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState(null);
    const [selected,setSelected] = useState(0);
    const [tiposMovimentos,setTiposMovimentos] = useState([])

    const [sending,setSending] = useState(false)

    const [produtos, setProdutos] = useState([]);
    const [produtosAll, setProdutosAll] = useState([]);

    const [movimentos, setMovimentos] = useState([]);
    
    const [open, setOpen] = useState(false);
    const [tipo, setTipo] = useState(1);

    const [registro,setRegistro] = useState({
        anexo: '',
        tipo:'',
        observacao:'',
        dtnota: new Date(),
        contrato:'',
        nota:'',
        valornota:'',
        local:'',
        motorista:'',
    });
    const [adicionar,setAdicionar] = useState({
        produto: '',
        qtd:'',
        vencimento: new Date(),
    });

    const loadMovimentos=()=>{
        LaConFetch(`/api/almoxarifado/movimento/index`,(r)=>{
            if(r.success){
                setMovimentos(r.items)
            }
        },()=>{})
    }

    useEffect(()=>{
        loadMovimentos()
    },[])
    
    useEffect(()=>{
        LaConFetch(`/api/almoxarifado/produto`,(r)=>{
            if(r.success){
                let hold = r.items.map(item=>{
                    return {
                        produto_id: item.id,
                        produto: item.nome
                    }
                })
                setProdutosAll(hold)
            }
        },()=>{})
    },[])

    useEffect(()=>{
        LaConFetch(`/api/almoxarifado/tipos`,(r)=>{
            if(r.success){
                let hold = r.items.filter(item=>!item.transferencia)
                setTiposMovimentos(hold)
            }
        },()=>{})
    },[])
    const handleAdicionar=(field,value)=>{
        let hold = {...adicionar}
        hold[field] = value
        setAdicionar(hold)
    }
    const addProdutos = () => {
        let hold = [...produtos]
        hold.push({
            produto_id: adicionar.produto.produto_id,
            produto: adicionar.produto.produto,
            validade: adicionar.produto.validade,
            qtd: adicionar.qtd,
            vencimento: moment(adicionar.vencimento).format('YYYY-MM-DD'),
        })
        setProdutos(hold)
        setAdicionar({
            produto: '',
            qtd:'',
            vencimento: new Date(),
        })
    }

    const handleOpen = () => {
        //clear();
        setOpen(true);
    };

    const handleClose = () => {
        //clear();
        setOpen(false);
    };

    
    const handleRegistro=(field,value)=>{
        let hold = {...registro}
        hold[field] = value
        if (field == 'tipo') {
            value = parseInt(value)
            setTipo(value)
        }
        setRegistro(hold)
    }

    const enviar = () => {
        setSending(true)
        let form = {
            movimentos:produtos,
            contrato:registro.contrato,
            nota:registro.nota,
            valor_nota:registro.valornota,
            local:registro.local,
            motorista:registro.motorista,
            data_nota:moment(registro.dtnota).format('YYYY-MM-DD'),
            codigo:'',
            obs:registro.observacao,
            tipo_id:tipo,
            beneficio_id:null,
        }

        LaConFetch(`/api/almoxarifado/movimento/multiplo`,(r)=>{
            if(r.success){
                if(r.alert){
                    swal("Movimento realizado!",r.message, "warning");
                }else{
                    swal("Movimento realizado com sucesso!","", "success");
                }
                loadMovimentos()
                setOpen(false)
            }else{
                swal("Houve um erro!",r.message,"erro");
            }
            setSending(false)
        },()=>{
            swal("Houve um erro!","Server Error","erro");
            setSending(false)
        },form)
    }
    
    return (<MuiPickersUtilsProvider utils={MomentUtils}>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="justify-content-center ml-5">
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
          <div className="clearfix">
          <h4 style={{float:'left',margin:'3px 0'}}>Movimentação de Estoque</h4>
          {(!open) ? (
          <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
            Novo Movimento
          </Button>) : (
          <Button onClick={handleClose} style={{ float: 'right' }}  variant="contained" color="warning">
            Lista de Movimentos
          </Button>
          )}
          </div>
        </div>
        
        {(!open) ? (
        <div>
        
          <MaterialTable 
                columns={[
                    { title: 'Cód', field: 'id', headerStyle: {fontWeight: 'bold'} },
                    { title: 'Data', field: 'created_at',render:(data)=>moment(data.created_at,'YYYY-MM-DD').format('DD/MM/YYYY'), headerStyle: {fontWeight: 'bold'} },
                    { title: 'Tipo', field: 'tipo.nome', headerStyle: {fontWeight: 'bold'} },
                    { title: 'Operador', field: 'user.name', headerStyle: {fontWeight: 'bold'} },
                ]}
                data={movimentos}
                actions={[
                    {
                        icon:'visibility',
                        tooltip:'Ver informações',
                        onClick:(e,data)=>{
                            setSelected(data.id)
                        }
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                    })
                }}
                localization={_MaterialTableLocalization}
                title="Lista de Solicitações" />
        </div>) : null}

        {(open) ? (<div>
        <Card style={{margin:'5px'}}>
            <CardContent>
                  
              <Grid container spacing={3}>

              <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 15 }}
                        id="tipo" 
                        value={registro.tipo}
                        label="Tipo de Movimento*"  
                        onChange={(e) => handleRegistro('tipo',e.target.value)}
                        select
                    >
                        {tiposMovimentos.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                  </TextField>
              </Grid>

                <Grid item lg={5}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 15 }}
                    id="produto_id" 
                    value={adicionar.produto}
                    label="Produto*"  
                    onChange={(e) => handleAdicionar('produto',e.target.value)}
                    select>
                    {produtosAll.map(item => <MenuItem value={item}>{item.produto}</MenuItem>)}
                  </TextField>
                </Grid>
                {(tipo == 1 || tipo == 2) ? (
                <Grid item lg={3}>
                    <KeyboardDatePicker
                        clearable
                        value={adicionar.vencimento}
                        onChange={date => handleAdicionar('vencimento',date)}
                        minDate={new Date()} label="Vencimento"
                        format="DD/MM/yyyy" margin='normal'
                        inputVariant='outlined' fullWidth
                    />
                </Grid>) : null}
                <Grid item lg={2}>
                  <TextField   
                    margin="normal"
                    fullWidth
                    id="qtd"
                    label="Quantidade"
                    variant="outlined"
                    value={adicionar.qtd} 
                    onChange={(e) => handleAdicionar('qtd',e.target.value)}
                  />
                </Grid>
                <Grid item lg={1}>
                  <Button variant="contained" size="large" color="primary" style={{padding:'17px',marginTop:'15px'}} onClick={()=>addProdutos()}>
                    Adicionar
                  </Button>
                </Grid>

                <Grid item lg={12}>
                <MaterialTable 

                    columns={[
                        { title: 'Produto', field: 'produto', headerStyle: {fontWeight: 'bold'} },
                        { title: 'Quantidade', field: 'qtd', headerStyle: {fontWeight: 'bold'} },
                    ]}

                    data={produtos}

                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Remover',
                            onClick: (e,data) => {
                                let hold = [...produtos]
                                hold.splice(data.tableData.id,1)
                                setProdutos(hold)
                            }
                        },
                    ]}

                    components={{Container:'div'}}

                    options={{
                        actionsColumnIndex: -1,
                        rowStyle: rowData => ({
                          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        }),
                        search: false,
                        showTitle: false,
                        toolbar: false
                    }}

                    localization={_MaterialTableLocalization}
                    />
                </Grid>

                {(tipo == 1) ? (<>
                <Grid item lg={12}>
                    <TextField   
                        margin="normal"
                        fullWidth
                        id="contrato"
                        label="Nº Processo/Contrato"
                        variant="outlined"
                        value={registro.contrato} 
                        onChange={(e) => handleRegistro('contrato',e.target.value)}
                    />
                </Grid>
                <Grid item lg={12}>
                    <TextField   
                        margin="normal"
                        fullWidth
                        id="nota"
                        label="Nº Nota Fiscal"
                        variant="outlined"
                        value={registro.nota} 
                        onChange={(e) => handleRegistro('nota',e.target.value)}
                    />
                </Grid>
                <Grid item lg={12}>
                    <KeyboardDatePicker
                        clearable
                        value={registro.dtnota}
                        onChange={date => handleRegistro('dtnota',date)}
                        minDate={new Date()}
                        format="DD/MM/yyyy" margin='normal'
                        inputVariant='outlined' fullWidth
                        label="Data da Nota Fiscal*"
                    />
                </Grid>
                <Grid item lg={12}>
                    <TextField   
                        margin="normal"
                        fullWidth
                        id="valornota"
                        label="Valor Total (R$)*"
                        variant="outlined"
                        value={registro.valornota} 
                        onChange={(e) => handleRegistro('valornota',e.target.value)}
                    />
                </Grid>
                </>) : null}

                {(tipo == 3) ? (<>
                <Grid item lg={12}>
                    <TextField   
                        margin="normal"
                        fullWidth
                        id="local"
                        label="Local*"
                        variant="outlined"
                        value={registro.local} 
                        onChange={(e) => handleRegistro('local',e.target.value)}
                    />
                </Grid>
                <Grid item lg={12}>
                    <TextField   
                        margin="normal"
                        fullWidth
                        id="motorista"
                        label="Nome do Motorista"
                        variant="outlined"
                        value={registro.motorista} 
                        onChange={(e) => handleRegistro('motorista',e.target.value)}
                    />
                </Grid>
                </>) : null}

                <Grid item lg={12}>
                    <TextField   
                    margin="normal"
                    fullWidth    
                    id="observacao"
                    label="Observação"
                    variant="outlined"
                    value={registro.observacao} 
                    onChange={(e) => handleRegistro('observacao',e.target.value)}
                    multiline='true'
                    rows='4'
                    />
                </Grid>

              </Grid>
              <br></br><br></br>

              <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>
            </CardContent>
        </Card>
    </div>):null}

   </div>
        <Modal open={selected>0} onClose={()=>setSelected(0)}>
            <div className='container' style={{marginTop:50}}>
                <ModalMovimento id={selected} onClose={()=>{
                    setSelected(0)
                    loadMovimentos()
                }}/>
            </div>
        </Modal>
   </MuiPickersUtilsProvider>)
}
import CrudView from 'components/Crud/CrudView'
import React from 'react'

export default function ProdutoAlmoxarifado({...props}){
    return <div>
        <CrudView
            url={'/api/almoxarifado/produto'}
            columns={[
                {field:'nome',title:'Nome'},
                {field:'unidade',title:'Unidade'},
                {field:'estoque_minimo',title:'Estoque Mínimo'},
            ]}
            title='Produto'
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:6}
                },{
                    field:'categoria_id',label:'Categoria',type:'select',grid:{xs:6},link:'/api/almoxarifado/categoria',nameKey:'nome',dataKey:'id'
                },{
                    field:'descricao',label:'Descrição',type:'text',input:{rows:4,multiline:true},grid:{xs:12}
                },{
                    field:'unidade',label:'Unidade',type:'text',grid:{xs:6}
                },{
                    field:'estoque_minimo',label:'Estoque Mínimo',type:'text',grid:{xs:6},input:{type:'number'}
                },{
                    field:'obs',label:'Observação',type:'text',input:{rows:4,multiline:true},grid:{xs:12}
                },
            ]}
        />
    </div>
}
import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, CircularProgress, Backdrop} from '@material-ui/core';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';
import ModaAnexo from 'components/Crud/ModaAnexo';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  input: {
    '&::placeholder': {
      fontStyle: 'italic',
    },
  },

}));
   
export default function EstudoSocial({familia_id}) {

  const classes = useStyles();
  
  const [ds_demanda_familiar, setDs_demanda_familiar] = useState("");
  const [ds_potenciais, setDs_potenciais] = useState("");
  const [ds_vulnerabilidades, setDs_vulnerabilidades] = useState("");
  const [ds_vulnerabilidades_reside, setDs_vulnerabilidades_reside] = useState("");
  const [ds_concessao_beneficio, setDs_concessao_beneficio] = useState("");
  const [ds_paif, setDs_paif] = useState("");
  const [ds_paefi, setDs_paefi] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  const [sending, setSending] = useState(false);

  /* Carregar Estudo Social */
  const load = () => {

    // setSending(true);

    // fetch(`${link}/api/resultEstudoSocial/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDados(result.dados))})
    //   .then(result => setSending(false))

    setSending(true);
    LaConFetch(`/api/resultEstudoSocial/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDados(result.dados)
        }
        setSending(false)
    },result => setSending(false))
        
    };
  
  useEffect(() => {
    
    load();

  }, [])

  /* SetDados */
  const setDados = (dados) => {

    /* Estudo Social */
    setDs_demanda_familiar(dados.ds_demanda_familiar);
    setDs_potenciais(dados.ds_potenciais);
    setDs_vulnerabilidades(dados.ds_vulnerabilidades);
    setDs_vulnerabilidades_reside(dados.ds_vulnerabilidades_reside);
    setDs_concessao_beneficio(dados.ds_concessao_beneficio);
    setDs_paif(dados.ds_paif);
    setDs_paefi(dados.ds_paefi);
    setDs_observacao(dados.ds_observacao);

  };

  /* Cadastrar EstudoSocial*/
  const enviar = () => {

    const formDados = {
      familia_id,
      ds_demanda_familiar, 
      ds_potenciais,
      ds_vulnerabilidades, 
      ds_vulnerabilidades_reside, 
      ds_concessao_beneficio,
      ds_paif,
      ds_paefi,
      ds_observacao};

    // setSending(true);

    // fetch(`${link}/api/cadastrarEstudoSocial`,{
    //     method:'POST',
    //     body:JSON.stringify(formDados),
    //     headers:{
    //         "Content-Type":'application/json',
    //         Accept:'application/json'
    //     }
    // })
    // .then(res => res.json())
    // .then(result => {
    //     setSending(false)
    // })
    // .then(swal("Salvo com sucesso!","", "success"));

    setSending(true);
    LaConFetch(`/api/cadastrarEstudoSocial`,
    result => {
      load();
      setSending(false);
      swal("Enviado com sucesso!","", "success")
    },()=>{},formDados)

  };

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Estudo Social</InputLabel>
      
      <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 11 }}>
        Demanda familiar apresentada:
      </InputLabel>
      <TextField
        margin="normal"
        style={{ width: '100%' }}  
        id="ds_demanda_familiar"
        variant="outlined"
        value={ds_demanda_familiar} 
        onChange={(e) => setDs_demanda_familiar(e.target.value)}
        multiline='true'
      />

      <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 11 }}>
        Identificações Potenciais da Família quanto:
      </InputLabel>
      <InputLabel style={{ marginTop: 20, fontSize: 11 }}>
        - Considerar:<br></br>
        Relações significativas (vizinhanças, família 
        ampliada, amigos, organizações religiosas, associações 
        comunitárias, grupos culturais, outros);<br></br>
        Envolvimento de membros da família em ações em que objetivam a superação 
        de vulnerabilidade; <br></br>
        Resgate ou reconhecimento de potenciais 
        adquiridos (habilidades manuais, experiências profissionais, 
        capacidades desenvolvidas, dentre outros); <br></br>
        Relação da família 
        com a rede de serviços existentes no território (saúde, 
        educação, assistência social, cultura, esporte, lazer, 
        outros) e Nível de participação em atividades ofertadas.
      </InputLabel>
      <TextField  
        margin="normal"
        style={{ width: '100%' }}       
        id="ds_potenciais"
        variant="outlined"
        value={ds_potenciais} 
        onChange={(e) => setDs_potenciais(e.target.value)}
        multiline='true'
      />

      <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 11 }}>
        Identificações das vulnerabilidades da Família quanto:
      </InputLabel>
      <InputLabel style={{ marginTop: 20, fontSize: 11 }}>
        - Considerar:<br></br>
        Condições socioenômicas;<br></br>
        Histórico de perdas ou interrupção de relações;<br></br>
        Histórico de violação de direitos;<br></br>
        Acesso ( ou necessidade de) ao Sistema de Garantia de Direitos. 
        Se já acessa, quais medidas/acompanhamentos realizados; se não, 
        indicar quais os encaminhamentos realizados;
      </InputLabel>
      <TextField  
        margin="normal"
        style={{ width: '100%' }}        
        id="ds_vulnerabilidades"
        variant="outlined"
        value={ds_vulnerabilidades} 
        onChange={(e) => setDs_vulnerabilidades(e.target.value)}
        multiline='true'
      />

      <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 11 }}>
       Identificações das vulnerabilidades/potencialidades do território onde a família reside:
      </InputLabel>
      <InputLabel style={{ marginTop: 20, fontSize: 11 }}>
        - Considerar:<br></br>
        Condições socioenômicas;<br></br>
        Características e especialidades do território que influenciam /e/ou determinam 
        as situações de vulnerabilidade vivenciadas pela família;<br></br>
        Características e especialidades do território que podem 
        minimizar as situações de vulnerabilidade vivenciadas pelas famílias;
      </InputLabel>
      <TextField  
        margin="normal"
        style={{ width: '100%' }}        
        id="ds_vulnerabilidades_reside"
        variant="outlined"
        value={ds_vulnerabilidades_reside} 
        onChange={(e) => setDs_vulnerabilidades_reside(e.target.value)}
        multiline='true'
      />

      <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 11 }}>
        Indicações da Família para:
      </InputLabel>
      <InputLabel style={{ marginTop: 20, fontSize: 11 }}>
        - Concessão de Benefício Eventual:
      </InputLabel>
      <TextField   
        margin="normal"
        style={{ width: '100%' }}   
        id="ds_concessao_beneficio"
        variant="outlined"
        value={ds_concessao_beneficio} 
        onChange={(e) => setDs_concessao_beneficio(e.target.value)}
        multiline='true'
      />
    
      <InputLabel style={{ marginTop: 20, fontSize: 11 }}>
        - Inserção no PAIF com açoes de:
      </InputLabel>
      <TextField   
        margin="normal"
        style={{ width: '100%' }}      
        id="ds_paif"
        variant="outlined"
        value={ds_paif} 
        onChange={(e) => setDs_paif(e.target.value)}
        multiline='true'
      />

      <InputLabel style={{ marginTop: 20, fontSize: 11 }}>
        - Inserção no PAEFI com açoes de:
      </InputLabel>
      <TextField   
        margin="normal"
        style={{ width: '100%' }}      
        id="ds_paefi"
        variant="outlined"
        value={ds_paefi} 
        onChange={(e) => setDs_paefi(e.target.value)}
        multiline='true'
      />

      <ModaAnexo url={'estudo-social'} endLink={`?familia_id=${familia_id}`}/>

      <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 11 }}>
        Observação
      </InputLabel>
      <TextField   
        margin="normal"
        style={{ width: '100%' }}     
        id="ds_observacao"
        variant="outlined"
        value={ds_observacao} 
        onChange={(e) => setDs_observacao(e.target.value)}
        multiline='true'
      />

      <br></br><br></br>
      
      <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
        Enviar
      </Button>

      <br></br><br></br>

    </div>
)}
import React, { useState } from 'react';
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { Badge } from '@material-ui/core';
import moment from 'moment';
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";
import { useEffect } from 'react';
import { LaConFetch } from 'links/LaConFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AlmoxarifadoDash({...props}){
    const [counters,setCounters] = useState({
        categoria: 0, produtos: 0, vencer: 0, kits: 0
    })
    const [agendas,setAgendas] = useState([])

    useEffect(()=>{
        LaConFetch(`/api/dashboard/almoxarifado`,(r)=>{
            if(r.success){
                setCounters(r.counters)
                setAgendas(r.agendas)
            }
        },()=>{})
    },[])

    const createLegend=(json)=> {
        let legend = [];
        for (let i = 0; i < json["names"].length; i++) {
          let type = "fa fa-circle text-" + json["types"][i];
          legend.push(<i className={type} key={i} />);
          legend.push(" ");
          legend.push(json["names"][i]);
        }
        return legend;
    }
    return <div className="content" style={{minHeight:'80vh'}}>
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-tag text-warning" />}
                statsText="Total de Categorias"
                statsValue={counters.categoria}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Atualizado agora"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fas fa-box" style={{color:'dodgerblue'}}/>}
                statsText="Total de Produtos"
                statsValue={counters.produtos}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Atualizado agora"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fas fa-clock" style={{color:'red'}}/>}
                statsText="Itens a vencer"
                statsValue={counters.vencer}
                statsIcon={<i className="fa fa-refresh" style={{color:'limegreen'}}/>}
                statsIconText="Atualizado agora"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fas fa-boxes"/>}
                statsText="Kits Disponiveis"
                statsValue={counters.kits}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Atualizado agora"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={9} xs={12}>

            </Col>
            <Col lg={3} xs={12}>
              <Calendar 
                  tileContent={(e)=>{
                      if(moment(e.date).isAfter(moment())){
                          let hold = agendas.filter(_ocor=>{
                              if(_ocor.start){
                                  if(moment(e.date).isSame(moment(_ocor.start,'YYYY-MM-DD'),'day')){
                                      return true
                                  }else{
                                      return false
                                  }
                              }else{
                                  return false
                              }
                          })
                          if(hold.length > 0){
                              return <Badge badgeContent={hold.length} max={99} className='float-right mr-1' color='secondary'/>
                          }else{
                              return <></>
                          }
                      }else{
                          return <></>
                      }
                  }}
                  className='w-100 border-0 shadow-sm rounded h-100'
                  calendarType='US'
                  
              />
            </Col>
        </Row>
        </Grid>
    </div>
}
/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Cadastro from "views/Cadastro.jsx";
import Prontuario from "views/Prontuario.jsx";
import Dashboard from "views/Dashboard.jsx";
import Home from "views/Home.jsx";
import Usuarios from "views/Usuarios.jsx";
import AlmoxBeneficios from "views/AlmoxBeneficios.jsx";
import AlmoxProdutos from "views/AlmoxProdutos.jsx";
import AlmoxMovimentos from "views/AlmoxMovimentos.jsx";
import AlmoxTransferir from "views/AlmoxTransferir.jsx";
import AlmoxEstoque from "views/AlmoxEstoque.jsx";
import Oficinas from "views/Oficinas.jsx";
import Perfil from "views/Perfil.jsx";
import Cargos from "views/Cargos";
import CategoriaAlmoxarifado from "views/Almoxarifado/CategoriaAlmoxarifado";
import ProdutoAlmoxarifado from "views/Almoxarifado/ProdutoAlmoxarifado";
import Relatorio from "views/Relatorio/Relatorio";
import Integracoes from "views/Integracoes";
import Log from "views/Log";
import Creas from "views/Creas";
import EncaminhamentoRecebido from "views/ModAtendimento/EncaminhamentoRecebido";
import Scfv from "views/Scfv";
import Comunicacao from "views/Comunicacao";
import Conselhos from "views/Conselhos";

// import UserProfile from "views/UserProfile.jsx";
// import TableList from "views/TableList.jsx";
// import Typography from "views/Typography.jsx";
// import Icons from "views/Icons.jsx";
// import Maps from "views/Maps.jsx";
// import Notifications from "views/Notifications.jsx";
// import Upgrade from "views/Upgrade.jsx";


const dashboardRoutes = ()=>{

  return [
    {
      path: "/home",
      name: "Inicial",
      icon: "pe-7s-home",
      component: Home,
      layout: "/admin",
      type: ['GER','TEC','DIR','REC','CON','ALM','ALA','TUT']
    },
    {
      path: "/cadastro",
      name: "Novo Atendimento",
      icon: "pe-7s-note",
      component: Cadastro,
      layout: "/admin",
      type: ['GER','TEC','REC']
    },
    {
      path: "/entrada",
      name: "Encaminhamentos Recebidos",
      icon: "pe-7s-mail",
      component: EncaminhamentoRecebido,
      layout: "/admin",
      type: ['GER','TEC','REC']
    },
    {
      path: "/prontuario",
      name: "Buscar Atendimento",
      icon: "pe-7s-note2",
      component: Prontuario,
      layout: "/admin",
      type: ['GER','TEC','REC']
    },
    {
      path: "/oficinas",
      name: "Oficinas e Ações Coletivas",
      icon: "pe-7s-users",
      component: Oficinas,
      layout: "/admin",
      type: ['GER','TEC']
    },
    {
      path: "/scfv",
      name: "Convivência e Fortalecimento de Vínculos",
      icon: "pe-7s-users",
      component: Scfv,
      layout: "/admin",
      type: ['GER','TEC']
    },
    {
      path: "/user",
      name: "Usuários",
      icon: "pe-7s-id",
      component: Usuarios,
      layout: "/admin",
      type: ['GER','CON','DIR']
    },
    {
      path: "/beneficios",
      name: "Benefícios",
      icon: "pe-7s-box2",
      component: AlmoxBeneficios,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/produtos",
      name: "Produtos",
      icon: "pe-7s-box1",
      component: AlmoxProdutos,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/movimentos",
      name: "Movimentos",
      icon: "pe-7s-next",
      component: AlmoxMovimentos,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/transferir",
      name: "Transferir",
      icon: "pe-7s-loop",
      component: AlmoxTransferir,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/estoque",
      name: "Estoque Geral",
      icon: "pe-7s-menu",
      component: AlmoxEstoque,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/almoxarifado/categoria",
      name: "Categorias",
      icon: "pe-7s-folder",
      component: CategoriaAlmoxarifado,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/almoxarifado/produto",
      name: "Produto",
      icon: "pe-7s-file",
      component: ProdutoAlmoxarifado,
      layout: "/admin",
      type: ['GER','ALM','ALA']
    },
    {
      path: "/relatorio",
      name: "Relatório",
      icon: "pe-7s-box1",
      component: Relatorio,
      layout: "/admin",
      type: ['GER','DIR']
    },
    {
      path: "/comunicacao",
      name: "Comunicação",
      icon: "pe-7s-phone",
      component: Comunicacao,
      layout: "/admin",
      type: ['GER','DIR']
    },
    {
      path: "/integracao",
      name: "Integrações",
      icon: "pe-7s-share",
      component: Integracoes,
      layout: "/admin",
      type: ['GER','DIR']
    },
    {
      path:'/conselhos',
      name: "Conselhos",
      icon: "pe-7s-map-2 ",
      component: Conselhos,
      layout: "/admin",
      type: ['GER','TUT']
    },
    {
      path: "/perfil",
      name: "Perfil",
      icon: "pe-7s-user",
      component: Perfil,
      layout: "/admin",
      type: ['GER','TEC','DIR','REC','CON','ALM','ALA','TUT']
    },{
      path: "/locais",
      name: "Locais",
      icon: "pe-7s-map-2 ",
      component: Creas,
      layout: "/admin",
      type: ['GER','DIR']
    },
    /*{
      path: "/cargos",
      name: "Cargos",
      icon: "pe-7s-notebook ",
      component: Cargos,
      layout: "/admin",
      type: ['GER']
    },*/
    {
      path: "/Log",
      name: "Log de acessos",
      icon: "pe-7s-monitor ",
      component: Log,
      layout: "/admin",
      type: ['GER']
    },
  ]
};

export default dashboardRoutes;

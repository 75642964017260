import CrudView from 'components/Crud/CrudView';
import React from 'react';
import moment from 'moment'

export default function Historico({familia_id,...props}){
    console.log(familia_id)
    return <div>
        <CrudView
            url={`/api/historico/index/${familia_id}`} title='Histórico'
            canEdit={false} canAdd={false} canDelete={false} canSee={true}
            columns={[
                {field:'created_at',title:'Realizado em',render:(data)=>moment(data.created_at,'YYYY-MM-DDTHH:mm').format('HH:mm DD/MM/YYYY')},
                {field:'titulo',title:'Ação'}
            ]}
            fields={[
                {
                    grid:{xs:6},field:'titulo',type:'text',label:'Ação:'
                },{
                    grid:{xs:6},field:'created_at',type:'datetime',label:'Criado em:'
                },{
                    grid:{xs:12},field:'descricao',type:'text',label:'Descrição:',input:{multiline:true,rows:15},
                }
            ]}
        />
    </div>
}
import { MenuItem, TextField } from '@material-ui/core'
import { LaConFetch } from 'links/LaConFetch'
import React, { useState, useEffect } from 'react'

export default function SelectFromAnother({link,value,onChange,nameKey='nome',dataKey='id',readOnly=false,label,required=false,...props}){
    const [options,setOptions] = useState([])

    useEffect(()=>{
        if(link){
            LaConFetch(`${link}`,(r)=>{
                if(r.success){
                    setOptions(r.items)
                }
            },()=>{})
        }
    },[link])
    return <div>
        <TextField
            variant="outlined" fullWidth margin='normal'
            value={parseInt(value) > 0?parseInt(value):value} required={required}
            label={label} disabled={readOnly}
            onChange={(e) => onChange(e.target.value)} select
            {...props}
        >
            <MenuItem value="">Nenhuma opção</MenuItem>
                {options.map(item=><MenuItem value={item[dataKey]}>{item[nameKey]}</MenuItem>)}
        </TextField>
    </div>
}
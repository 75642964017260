import CrudView from 'components/Crud/CrudView';
import React from 'react';

export default function Creas({...props}){
    return <div>
        <CrudView
            title='Local' url='/api/creas'
            columns={[
                {
                    field:'nome',title:'Nome',
                },{
                    field:'rua',title:'Rua',
                },{
                    field:'numero',title:'Número',
                },{
                    field:'bairro',title:'Bairro',
                },{
                    field:'cep',title:'Cep',
                },{
                    field:'tipo',title:'Tipo',render:(data)=>{
                        switch(data.tipo){
                            case 'creas':
                                return 'Creas';
                            case 'cras':
                                return 'Cras';
                            default:
                                return data.tipo
                        }
                    }
                },
            ]}
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:6}
                },{
                    field:'tipo',label:'Tipo',type:'select',grid:{xs:6},link:'/api/creas/tipo',nameKey:'nome',dataKey:'id'
                },{
                    field:'cep',label:'CEP',type:'text',grid:{xs:12}
                },{
                    field:'rua',label:'Rua',type:'text',grid:{xs:8}
                },{
                    field:'numero',label:'Número',type:'text',grid:{xs:4}
                },{
                    field:'complemento',label:'Complemento',type:'text',grid:{xs:6}
                },{
                    field:'bairro',label:'Bairro',type:'text',grid:{xs:6}
                },{
                    field:'cidade',label:'Cidade',type:'text',grid:{xs:6}
                },
            ]}
        />
    </div>
}
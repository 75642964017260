import React, { useEffect, useState } from 'react';
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { LaConFetch } from 'links/LaConFetch';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { Badge } from '@material-ui/core';
import moment from 'moment';

export default function DiretorDash({...props}){
    const [counters,setCounters] = useState({
        beneficiados: 0, familias: 0, creas: 0, cras: 0,
    })
    const [familias,setFamilias] = useState([])
    const [locais,setLocais] = useState([])
    const [agendas,setAgendas] = useState([])

    useEffect(()=>{
        LaConFetch(`/api/dashboard/diretor`,(r)=>{
            if(r.success){
                setCounters(r.counters)
                setFamilias(r.familias)
                setLocais(r.locais)
                setAgendas(r.agendas)
            }
        },()=>{})
    },[])

    return <div className="content">
    <Grid fluid>
      <Row>
        <Col lg={3} sm={6}>
          <StatsCard
            bigIcon={<i className="fas fa-user text-warning" />}
            statsText="Total de Beneficiados"
            statsValue={counters.beneficiados}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="Atualizado agora"
          />
        </Col>
        <Col lg={3} sm={6}>
          <StatsCard
            bigIcon={<i className="fas fa-users" style={{color:'dodgerblue'}}/>}
            statsText="Total de Familías"
            statsValue={counters?.familias}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="Atualizado agora"
          />
        </Col>
        <Col lg={3} sm={6}>
          <StatsCard
            bigIcon={<i className="fas fa-building" style={{color:'red'}}/>}
            statsText="Total de Creas"
            statsValue={counters.creas}
            statsIcon={<i className="fa fa-refresh"/>}
            statsIconText="Atualizado agora"
          />
        </Col>
        <Col lg={3} sm={6}>
          <StatsCard
            bigIcon={<i className="fas fa-building" style={{color:'limegreen'}}/>}
            statsText="Total de Cras"
            statsValue={counters.cras}
            statsIcon={<i className="fa fa-refresh" />}
            statsIconText="Atualizado agora"
          />
        </Col>
      </Row>
      <Row>
          <Col lg={9} xs={12}>
                <GoogleMap
                    mapContainerStyle={{height:'40vh',width:'100%'}}
                    zoom={14}
                    center={{ lat:-22.888542919206664, lng:-48.440817461990804 }}
                >
                    {locais.map(item=><Marker key={item.id} position={{lat:item.lat,lng:item.lng}}/>)}
                    {familias.map(item=><Marker key={item.id} position={{lat:item.lat,lng:item.lng}}/>)}
                </GoogleMap>
          </Col>
          <Col lg={3} xs={12}>
            <Calendar 
                tileContent={(e)=>{
                    if(moment(e.date).isAfter(moment())){
                        let hold = agendas.filter(_ocor=>{
                            if(_ocor.start){
                                if(moment(e.date).isSame(moment(_ocor.start,'YYYY-MM-DD'),'day')){
                                    return true
                                }else{
                                    return false
                                }
                            }else{
                                return false
                            }
                        })
                        if(hold.length > 0){
                            return <Badge badgeContent={hold.length} max={99} className='float-right mr-1' color='secondary'/>
                        }else{
                            return <></>
                        }
                    }else{
                        return <></>
                    }
                }}
                className='w-100 border-0 shadow-sm rounded h-100'
                calendarType='US'
                
            />
          </Col>
      </Row>
    </Grid>
    </div>

}
import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, CircularProgress, Backdrop, Modal} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

}));
   
export default function Rendimentos({familia_id}) {

  const classes = useStyles();

  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  
  // Programa Social
  const [id, setId] = useState("");
  const [cd_programa_social, setCd_programa_social] = useState("");
  const [nm_programa_social, setNm_programa_social] = useState("");
  const [vl_programa_social, setVl_programa_social] = useState("");

  const [nm_programa_social_outro, setNm_programa_social_outro] = useState("");
  const [outroProgramaOff, setOutroProgramaOff] = useState(true);

  /* Set Programa */
  const setPrograma = (codigo) =>{

    setCd_programa_social(codigo);

    if (codigo === '1'){  
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Bolsa Família');
    }
    else if (codigo === '2'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('BPC');
    }      
    else if (codigo === '3'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Programa de Erradicação do Trabalho Infantil (PETI)');
    }
    else if (codigo === '4'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Renda Cidadã');
    }
    else if (codigo === '5'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Renda Cidadã/Idoso');
    }
    else if (codigo === '6'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Ação Jovem');
    }
    else if (codigo === '7'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Frente de Trabalho');
    }
    else if (codigo === '8'){
      setNm_programa_social_outro(null);
      setOutroProgramaOff(true);
      setNm_programa_social('Viva Leite');
    }
    else if(codigo === '9'){
      setOutroProgramaOff(false);
    }
    
  }
  
  const setProgramaOutro = (texto) =>{
  
    setNm_programa_social_outro(texto);
    setNm_programa_social(texto);
  }

  /* Carregar Pessoa e Programa Social*/
  const [rows, setRows] = useState([]);

  const loadPrograma = () => {

    setSending(true);

    // fetch(`${link}/api/resultPessoa/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDadosPessoa(result.dados))})
        
    // fetch(`${link}/api/resultProgramaSocial/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setRows(result.dados))})
    //   .then(result => setSending(false))

    LaConFetch(`/api/resultPessoa/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDadosPessoa(result.dados)
        }
        setSending(false)
    },result => setSending(false))

    LaConFetch(`/api/resultProgramaSocial/${familia_id}`,
      result => {
        if(result.dados.length > 0){
          setProgramaObj(result.dados)
        }
        setSending(false)
    },result => setSending(false))

  };
  
  useEffect(() => {
    
    loadPrograma();
    
  }, [])

  const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

  /* SetDadosProgramaSocial */
  const setDadosProgramaSocial = (dados) => {

    /* Dados */
    setId(dados.id);
    setPrograma(dados.cd_programa_social);
    if(dados.cd_programa_social === '9'){
      setProgramaOutro(dados.nm_programa_social);
    }
    setVl_programa_social(dados.vl_programa_social);
    setNm_pessoa(dados.nm_pessoa);
    setPessoa_id(dados.pessoa_id);

  };

   /* Limpa os dados */
   const clear = () => {

    /* Dados */
    setId("");
    setCd_programa_social("");
    setNm_programa_social("")
    setVl_programa_social("");
    setNm_programa_social_outro("");
    setNm_pessoa("");
    setPessoa_id("");
  };

  const [open, setOpen] = React.useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpen = () => {
    clear();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const modalEdit = (dados) => {
    setDadosProgramaSocial(dados);
    setOpenEdit(true);
  };

  /* Remover Programa Social */
  const removerPrograma = (dados) => {

    const id = dados.id;

    setSending(true);

    // fetch(`${link}/api/removerProgramaSocial/${id}`,{
    //     method:'POST',               
    // })
    // .then(result => {
    //     loadRendimentos();
    //     setSending(false);
    // });

    LaConFetch(`/api/removerProgramaSocial/${id}`,
      result => {
        setSending(false)
    },result => setSending(false),{})

  };

  /* Despesa */
  const [despesa, setDespesa] = useState([{nm_despesa:"", vl_despesa:"", botao:true}]);
  const [programa, setProgramaObj] = useState([{cd_programa_social:"", vl_programa_social:"",pessoa_id:null, botao:true}]);

  /* Add Despesa */
  const addDespesa = (pos) =>{
    let add = [...despesa];
    add.push({nm_despesa:"", vl_despesa:"", botao:true});
    add[pos].botao = false;
    setDespesa(add);
  }

  /* Remove Despesa */
  const closeDespesa = (pos) =>{
    let desp = [...despesa];
    desp.splice(pos,1);
    setDespesa(desp);
  }

  /* Set Nome Despesa */
  const setNm_despesa = (valor,pos) =>{
    let add = [...despesa];
    add[pos].nm_despesa = valor;
    setDespesa(add);
  }

  /* Set Valor Despesa */
  const setVl_despesa = (valor,pos) =>{
    let add = [...despesa];
    add[pos].vl_despesa = valor;
    setDespesa(add);
  } 

  /* Cadastrar Despesa */
  const enviarDespesa = () => {

    let continua = false;

    for(let item of despesa){

      if(item.nm_despesa === "" && item.vl_despesa !== ""){
        continua = false;
        swal("Nome da despesa é obrigatório para o envio!","", "error");
        break;
      }

      else if(item.vl_despesa === "" && item.nm_despesa !== ""){
        continua = false;
        swal("Valor da despesa é obrigatório para o envio!","", "error");
        break;
      }

      else{
        continua = true;
      }
    };

    if (continua === true) {

      setSending(true);


      LaConFetch(`/api/cadastrarDespesa`,
      result => {
        loadRendimentos();
        setSending(false);
        swal("Enviado com sucesso!","", "success")
        setOpen(false)
      },()=>{},{despesa,familia_id})

    }
  };

   /* Add Programa */
   const addPrograma = (pos) =>{
    let add = [...programa];
    add.push({cd_programa_social:"", vl_programa_social:"",pessoa_id:null, botao:true});
    add[pos].botao = false;
    setProgramaObj(add);
  }

  /* Remove Programa */
  const closePrograma = (pos) =>{
    let desp = [...programa];
    desp.splice(pos,1);
    setProgramaObj(desp);
  }

  const onChangePrograma=(field,value,pos)=>{
    let hold = [...programa]
    hold[pos][field] = value
    setProgramaObj(hold)
  }

  const enviarPrograma = () => {

    let continua = false;
    console.log(programa)
    for(let item of programa){
        if(item.cd_programa_social === ""){
          continua = false;
          swal("Programa Social não selecionado!","", "error");
          break;
        }else if(item.vl_programa_social === ""){
          continua = false;
          swal("Valor de programa social inválido!","", "error");
          break;
        }else if(item.pessoa_id === ""){
          continua = false;
          swal("Programa social sem beneficiado!","", "error");
          break;
        }else{
          continua = true;
        }
    };

    if (continua === true) {

      setSending(true);

      // fetch(`${link}/api/cadastrarPrograma`,{
      //     method:'POST',
      //     body:JSON.stringify(programa),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(swal("Enviado com sucesso!","", "success"))
      // .then(result => {
      //     loadRendimentos();
      //     setSending(false);
      // });

      LaConFetch(`/api/cadastrarProgramaSocial`,
      result => {
        loadPrograma();
        setSending(false);
        swal("Enviado com sucesso!","", "success")
        setOpen(false)
      },()=>{},{programa,familia_id})

    }
  };

  /* Carregar Despesa */
  const loadDespesa = () => {

    setSending(true);

    // fetch(`${link}/api/resultDespesa/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDadosDespesa(result.dados))})
    //   .then(result => setSending(false))

    LaConFetch(`/api/resultDespesa/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDadosDespesa(result.dados)
        }
        setSending(false)
    },result => setSending(false))
        
    };
  
    useEffect(() => {
      
      loadDespesa();
      
    }, [])

  useEffect(() => {
    
    //loadPrograma();
    
  }, [])

  /* SetDados Despesa */
  const setDadosPrograma = (dados) => {

    /* Despesa */
    let holdPrograma = dados.map( (item,pos) => {

      return {
        
        cd_programa_social:item.cd_programa_social, 
        vl_programa_social:item.vl_programa_social,
        pessoa_id:item.pessoa_id,
        botao: pos === dados.length-1
      
      }
    })
    
    setProgramaObj(holdPrograma)

  };
  

  /* Rendimentos */
  const [vl_renda_total_sem_ps, setVl_renda_total_sem_ps] = useState("");
  const [vl_renda_per_capita_sem_ps, setVl_renda_per_capita_sem_ps] = useState("");
  const [vl_total_beneficios, setVl_total_beneficios] = useState("");
  const [vl_total_despesas, setVl_total_despesas] = useState("");
  const [vl_renda_total_com_ps, setVl_renda_total_com_ps] = useState("");
  const [vl_renda_per_capita_com_ps, setVl_renda_per_capita_com_ps] = useState("");

  const [sending, setSending] = useState(false);

  /* Carregar Rendimentos */
  const loadRendimentos = () => {

    setSending(true);

    // fetch(`${link}/api/resultRendimentos/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDados(result.dados))})
    //   .then(result => setSending(false))

    LaConFetch(`/api/resultRendimentos/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDados(result.dados)
        }
        setSending(false)
    },result => setSending(false))
        
    };
  
    useEffect(() => {
      
      loadRendimentos();
      
    }, [])
  
    /* SetDados */
    const setDados = (dados) => {
  
      /* Rendimentos */
      setVl_renda_total_sem_ps(dados.vl_renda_total_sem_ps);
      setVl_renda_per_capita_sem_ps(dados.vl_renda_per_capita_sem_ps);
      setVl_total_beneficios(dados.vl_total_beneficios);
      setVl_total_despesas(dados.vl_total_despesas);
      setVl_renda_total_com_ps(dados.vl_renda_total_com_ps);
      setVl_renda_per_capita_com_ps(dados.vl_renda_per_capita_com_ps);
  
    };

    /* SetDados Despesa */
    const setDadosDespesa = (dados) => {
  
      /* Despesa */
      let holdingDespesa = dados.map( (item,pos) => {
  
        return {
          
          nm_despesa: item.nm_despesa,
          vl_despesa: item.vl_despesa,
          botao: pos === dados.length-1
       
        }
      })
      
      setDespesa(holdingDespesa)
  
    };

  return (

    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      {/* Programa Social */}
      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Programas Sociais e Outros Benefícios</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ float: 'right', width:'100%' }}>
          <div className="row">
            <div className="col-lg-4">Programa/Projeto</div>
            <div className="col-lg-4">Valor</div>
            <div className="col-lg-4">Beneficiário</div>
          </div>
          {programa.map((item, pos)=>
              <div className="row">
                <div className="col-lg-3">
                <TextField
                  variant="outlined"
                  hidden='true' 
                  style={{ marginTop: 15, marginRight: '2%', width: '100%' }} 
                  id="cd_programa_social"                
                  label="Programas Sociais*"
                  value={item.cd_programa_social} 
                  onChange={(e) => onChangePrograma('cd_programa_social',e.target.value,pos)}
                  select>
                  <MenuItem value="1">Bolsa Família</MenuItem>
                  <MenuItem value="2">BPC</MenuItem>
                  <MenuItem value="3">Programa de Erradicação do Trabalho Infantil (PETI)</MenuItem>
                  <MenuItem value="4">Renda Cidadã</MenuItem> 
                  <MenuItem value="5">Renda Cidadã/Idoso</MenuItem> 
                  <MenuItem value="6">Ação Jovem</MenuItem> 
                  <MenuItem value="7">Frente de Trabalho</MenuItem> 
                  <MenuItem value="8">Viva Leite</MenuItem> 
                  <MenuItem value="9">Outro</MenuItem>                         
                </TextField>
                </div>
                <div className="col-lg-3">
                <TextField  
                  margin="normal"
                  style={{ width: '100%' }}      
                  id="vl_programa_social"
                  label="Valor Recebido*"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  value={item.vl_programa_social} 
                  onChange={(e) => onChangePrograma('vl_programa_social',e.target.value,pos)}
                />
                </div>
                <div className="col-lg-3">
                <TextField
                  variant="outlined"
                  style={{ marginTop: 15, width: '100%' }} 
                  id="pessoa_id" 
                  value={item.pessoa_id}
                  label="Beneficiado*"  
                  onChange={(e) => onChangePrograma('pessoa_id',e.target.value,pos)}
                  select>
                    {dadosPessoa.map(item =>
                      <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                    )}
                </TextField>
                </div>
                <div className="col-lg-3">
                {item.botao === true?
                  <Fab style={{ marginTop:21, marginRight: '40%' }} onClick={(e) => addPrograma(pos)} size="small" color="primary" aria-label="addFaixa">
                    <AddIcon />
                  </Fab>
                :null}
                {item.botao === false?
                  <Fab style={{ marginTop:21, marginRight: '40%' }} onClick={(e) => closePrograma(pos)} size="small" color="secundary" aria-label="closeFaixa">
                    <CloseIcon />
                  </Fab>
                :null}
                </div>

              </div>)
            }
            <div className="row">
            <div className="col-lg-12">
              <br/><br/><br/><br/>
            <Button onClick={enviarPrograma} style={{ float: 'right' }}  variant="contained" color="primary">
              Salvar Programas
            </Button>
            </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <br></br><br></br>

      {/* Despesa */}
      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Despesas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ float: 'right', width:'100%' }}>
    
            {/* Despesa */}
            <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Despesas</InputLabel>
            
            {despesa.map((item, pos)=>
              <div>
                <TextField
                  margin="normal"
                  style={{ marginRight: '2%', width: '20%' }}  
                  id="nm_despesa"
                  label="Nome da despesa"
                  variant="outlined"
                  value={item.nm_despesa} 
                  onChange={(e) => setNm_despesa(e.target.value, pos)}
                />

                <TextField  
                  margin="normal"
                  style={{  marginRight: '2%', width: '20%' }}      
                  id="vl_despesa"
                  label="Valor da Despesa"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  value={item.vl_despesa} 
                  onChange={(e) => setVl_despesa(e.target.value, pos)}
                />

                {item.botao === true?
                  <Fab style={{ marginTop:21, marginRight: '40%' }} onClick={(e) => addDespesa(pos)} size="small" color="primary" aria-label="addFaixa">
                    <AddIcon />
                  </Fab>
                :null}

                {item.botao === false?
                  <Fab style={{ marginTop:21, marginRight: '40%' }} onClick={(e) => closeDespesa(pos)} size="small" color="secundary" aria-label="closeFaixa">
                    <CloseIcon />
                  </Fab>
                :null}

              </div>)
            }

            <div className="row">
            <div className="col-lg-12">
            <Button onClick={enviarDespesa} style={{ float: 'right' }}  variant="contained" color="primary">
              Salvar Despesas
            </Button>
            </div>
            </div>

            <br></br><br></br>

          </Typography>
        </AccordionDetails>
      </Accordion>

      <br></br><br></br>

      <Accordion expanded="true">
        <AccordionDetails>
          <Typography style={{ float: 'right', width:'100%' }}>

            {/* Rendimentos */}
            <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Renda Familiar</InputLabel>
            
            <TextField
              margin="normal"
              style={{ marginRight: '2%', width: '30%' }}  
              id="vl_renda_total_sem_ps"
              label="Valor da renda total sem programa social"
              variant="outlined"
              disabled={true}
              value={vl_renda_total_sem_ps} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setVl_renda_total_sem_ps(e.target.value)}
            />

            <TextField  
              margin="normal"
              style={{  marginRight: '2%', width: '30%' }}      
              id="vl_renda_per_capita_sem_ps"
              label="Valor da renda per capita sem programa social"
              variant="outlined"
              disabled={true}
              value={vl_renda_per_capita_sem_ps} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setVl_renda_per_capita_sem_ps(e.target.value)}
            />       

            <TextField   
              margin="normal"
              style={{ width: '20%' }}     
              id="vl_total_beneficios"
              label="Valor Total Benefícios"
              variant="outlined"
              disabled={true}
              value={vl_total_beneficios} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setVl_total_beneficios(e.target.value)}
            />

            <TextField   
              margin="normal"
              style={{ marginRight: '2%', width: '20%' }}     
              id="vl_total_despesas"
              label="Valor Total Despesas"
              variant="outlined"
              disabled={true}
              value={vl_total_despesas} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setVl_total_despesas(e.target.value)}
            />

            <TextField   
              margin="normal"
              style={{ marginRight: '2%', width: '30%' }}     
              id="vl_renda_total_com_ps"
              label="Valor da renda total com programa social"
              variant="outlined"
              disabled={true}
              value={vl_renda_total_com_ps} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setVl_renda_total_com_ps(e.target.value)}
            />

            <TextField   
              margin="normal"
              style={{ width: '30%' }}     
              id="vl_renda_per_capita_com_ps"
              label="Valor da renda per capita com programa social"
              variant="outlined"
              disabled={true}
              value={vl_renda_per_capita_com_ps} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setVl_renda_per_capita_com_ps(e.target.value)}
            />

          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
)}